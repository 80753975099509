::-webkit-scrollbar {
  display: none;
}
.backtotopicon{
  position: fixed;
  bottom: 30px;
  background-color: #fc5e28;
  right: 30px;
  height: 50px;
  width: 50px;
  text-align: center;
  padding-top: 9px;
  border-radius: 50%;
  cursor: pointer;
}
.backtotopicon svg{
  color: white;
}
